const { createHttpLink, ApolloLink, Observable } = require('@apollo/client')
const fetch = require('isomorphic-fetch')
const { RetryLink } = require('@apollo/client/link/retry')
const { onError } = require('@apollo/client/link/error')
const pLimit = require('p-limit')
const { RateLimiter } = require('limiter')

function stringifyLocation(location) {
  if (!location) {
    return '-'
  }
  const locations = !Array.isArray(location) ? [location] : location
  return locations.map((loc) => `[${loc.line}, ${loc.column}]`).join(', ')
}

function stringifyPath(path) {
  return path ? path.join('.') : '-'
}

const LogEmptyResponses = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    return forward(operation).subscribe({
      next(result) {
        if (!result.data) {
          // eslint-disable-next-line no-console
          console.log(
            `[GraphQL Empty Result]: Name: ${
              operation.operationName
            }, Variables: ${JSON.stringify(operation.variables)}`,
          )
        }

        observer.next(result)
      },
      error(error) {
        observer.error(error)
      },
      complete() {
        observer.complete()
      },
    })
  })
})

class RateLimitLink extends ApolloLink {
  /**
   * @param {object} options
   * @param {number} options.rateLimit
   */
  constructor({ rateLimit }) {
    const limit = pLimit(rateLimit)
    const rateLimiter = new RateLimiter(rateLimit, 'second')
    const requester = (operation, next, complete) => {
      return new Promise((resolve, reject) => {
        rateLimiter.removeTokens(1, (error) => {
          if (error) {
            reject(error)
          } else {
            next(operation).subscribe({
              next: resolve,
              error: reject,
              complete,
            })
          }
        })
      })
    }

    super((operation, next) => {
      return new Observable((observer) => {
        limit(() => {
          return requester(operation, next, () => {
            setTimeout(() => {
              observer.complete()
            })
          })
        })
          .then(observer.next.bind(observer))
          .catch(observer.error.bind(observer))
      })
    })
  }
}

const createLink = (pluginOptions) => {
  return ApolloLink.from([
    LogEmptyResponses,
    onError(({ graphQLErrors, operation, response }) => {
      if (graphQLErrors) {
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL Operation failed]: Name: ${
            operation.operationName
          }, Variables: ${JSON.stringify(operation.variables)}`,
        )
        graphQLErrors.map(({ message, locations, path }) =>
          // eslint-disable-next-line no-console
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${stringifyLocation(
              locations,
            )}, Path: ${stringifyPath(path)}`,
          ),
        )
      }

      if (response && response.errors) {
        response.errors = response.errors.filter(
          (error) => !error.message.includes('Query execution error'),
        )
      }
    }),
    new RetryLink({
      delay: {
        initial: 200,
        max: 2000,
        jitter: false,
      },
      attempts: {
        max: 5,
      },
    }),
    onError(({ networkError, operation }) => {
      const headerName = 'x-contentful-ratelimit-reset'
      if (
        networkError &&
        networkError.response &&
        networkError.response.headers.has(headerName)
      ) {
        operation.setContext({
          delayInSeconds: parseFloat(
            networkError.response.headers.get(headerName),
          ),
        })
      }
    }),
    new RateLimitLink({ rateLimit: 55 }),
    createHttpLink({
      uri: pluginOptions.url,
      headers: pluginOptions.headers,
      batch: true,
      dataLoaderOptions: {
        maxBatchSize: 10,
      },
      fetchOptions: {
        useGETForQueries: true,
      },
      fetch,
    }),
  ])
}

module.exports = {
  stringifyLocation,
  stringifyPath,
  default: createLink,
}
