import { gql, useQuery } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { Button } from '@dfds-ui/react-components'
import { graphql } from 'gatsby'
import { mergeQueries } from 'gatsby-plugin-dfds-contentful/utils/merge-queries'
import queryString from 'query-string'
import React from 'react'
import { adopt } from 'react-adopt'
import { ErrorBoundary } from 'react-error-boundary'
import { previewClient } from '../apollo/client'
import { useAppShellContext } from '../components/AppShellProvider'
import { stringifyLocation, stringifyPath } from './../../gatsby/createLink'
import * as allTemplates from './../index'
import GenericPage from './../templates/GenericPage'

const useAppShellQuery = ({ query, locale }) => {
  const { loading, data, error, refetch } = useQuery(
    gql`
      ${query}
    `,
    {
      client: previewClient,
      variables: { locale, preview: true },
    },
  )
  const { setAppShellContext } = useAppShellContext()
  React.useEffect(() => {
    if (data) {
      setAppShellContext({
        footerCollection: data.footerCollection,
        navigationCollection: data.navigationCollection,
      })
    }
  }, [data, setAppShellContext])

  return { loading, data, error: error, refetch }
}

const ErrorDisplayBase = ({ children }) => (
  <div style={{ color: 'white', backgroundColor: 'darkred', padding: 20 }}>
    {children}
  </div>
)

const ErrorDisplay = ({ error }) => {
  return (
    <div style={{ color: 'white', backgroundColor: 'darkred', padding: 20 }}>
      {error.graphQLErrors &&
        error.graphQLErrors.map(({ message, locations, path }, i) => {
          return (
            <div
              key={message}
              style={{
                marginBottom: i === error.graphQLErrors.length - 1 ? 0 : 10,
              }}
            >{`[GraphQL error]: Message: ${message}, Location: ${stringifyLocation(
              locations,
            )}, Path: ${stringifyPath(path)}`}</div>
          )
        })}
      {error.networkError && `[Network error]: ${error.networkError}`}
    </div>
  )
}

const GenericPagePreviewData = ({ queries, onRefetch }) => {
  const queriesArray = Object.values(queries)
  const isLoading = queriesArray.some((value) => {
    return value.loading
  })
  const data =
    !isLoading &&
    queriesArray
      .map((item) => item.data)
      .reduce((obj, queryData) => mergeQueries(obj, queryData), null)
  const hasErrors = queriesArray.some((value) => {
    return value.error
  })
  return (
    <>
      <Button
        onClick={() => {
          if (onRefetch) {
            onRefetch()
          }
          queriesArray.forEach((q) => q.refetch())
        }}
      >
        REFRESH
      </Button>
      {hasErrors && (
        <div>
          {queriesArray.map((index, value) => {
            return (
              value.error && <ErrorDisplay error={value.error} key={index} />
            )
          })}
        </div>
      )}
      {data && !isLoading && !hasErrors && (
        <GenericPage
          pageContext={{
            data: {
              content: data,
            },
          }}
        />
      )}
    </>
  )
}

const GenericPagePreview = ({ queries, locale, id, onRefetch }) => {
  const GenericPageQueries = adopt(
    queries.reduce((acc, curr, index) => {
      const GQuery = ({ render }) => (
        <Query
          query={gql`
            ${curr}
          `}
          variables={{ locale, id, preview: true }}
          client={previewClient}
        >
          {render}
        </Query>
      )
      return {
        ...acc,
        [index]: GQuery,
      }
    }, {}),
  )
  return (
    <GenericPageQueries>
      {(queries) => (
        <GenericPagePreviewData queries={queries} onRefetch={onRefetch} />
      )}
    </GenericPageQueries>
  )
}

const TemplatePreview = ({ query, locale, id, Template, onRefetch }) => {
  const { data, error, refetch } = useQuery(
    gql`
      ${query}
    `,
    {
      client: previewClient,
      variables: { locale, id, preview: true },
    },
  )

  return (
    <>
      <Button
        onClick={() => {
          if (onRefetch) {
            onRefetch()
          }
          refetch()
        }}
      >
        REFRESH
      </Button>
      {error && <ErrorDisplay error={error} />}
      {data && <Template data={{ content: data }} />}
    </>
  )
}

const PreviewTemplateBase = ({ pageContext, location }) => {
  const [locale, setLocale] = React.useState(
    queryString.parse(location.search).locale || 'en',
  )
  const [template] = React.useState(
    queryString.parse(location.search).template || 'GenericPage',
  )
  const [id] = React.useState(
    queryString.parse(location.search).id || '1EXHGiEZPgxeAz5FFAati6',
  )

  const {
    data: appShellData,
    error: appShellError,
    refetch: refetchAppShell,
  } = useAppShellQuery({
    query: pageContext.operations.preview,
    locale,
  })

  const handleLocaleChange = (e) => {
    setLocale(e.target.value)
    refetchAppShell()
  }
  const handleRefetch = () => {
    refetchAppShell()
  }

  return (
    <div>
      {appShellData && (
        <select onChange={handleLocaleChange} value={locale}>
          {appShellData.siteSettings.availableLanguagesCollection.items.map(
            ({ entryTitle, locale }) => (
              <option key={locale} value={locale}>
                {entryTitle}
              </option>
            ),
          )}
        </select>
      )}
      {appShellError && <ErrorDisplay error={appShellError} />}
      {template === 'GenericPage' && (
        <GenericPagePreview
          locale={locale}
          queries={pageContext.operations.GenericPage}
          onRefetch={handleRefetch}
          id={id}
        />
      )}
      {template !== 'GenericPage' && allTemplates[template] && (
        <TemplatePreview
          query={pageContext.operations[template]}
          locale={locale}
          id={id}
          Template={allTemplates[template]}
          onRefetch={handleRefetch}
        />
      )}
    </div>
  )
}

const PreviewTemplate = ({ pageContext, location }) => {
  const { accessToken } = queryString.parse(location.search)
  const [isAuth, setIsAuth] = React.useState(false)
  React.useEffect(() => {
    if (accessToken !== process.env.GATSBY_CONTENTFUL_PREVIEW_ACCESS_TOKEN) {
      window.location.replace('/')
      return
    }
    setIsAuth(true)
  }, [accessToken])
  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => (
        <ErrorDisplayBase>{error.message}</ErrorDisplayBase>
      )}
    >
      {isAuth && (
        <PreviewTemplateBase pageContext={pageContext} location={location} />
      )}
    </ErrorBoundary>
  )
}

export const query = graphql`
  query PreviewTemplate($locale: String! = "en") {
    content: contentful {
      siteSettings(id: "6R1gjaa1GMsWoGOU2GKiU4", locale: $locale) {
        ...SiteSettings
      }
    }
  }
`

export default PreviewTemplate
