import { graphql } from 'gatsby'
import { PageLayout } from '../components/Layout'
import { Trustpilot } from '../components/Trustpilot'
import { ExperienceCard } from '../components/ExperienceCard'
import { LinkboxCarousel } from '../components/LinkboxCarousel'
import { TextAndMediaLayout } from '../components/TextAndMedia'
import { PriceDisclaimer } from '../components/PriceDisclaimer'
import { NewsletterSubscribeBlock } from '../components/Newsletter'
import { Faq } from '../components/FAQ'
import { PaxCovidBanner } from '../components/PaxCovidBanner'
import Hero from '../components/Hero/Hero'

const CabinPage = ({ data: { content } }) => {
  const {
    hero,
    covidBannerLayout,
    miniBookReference,
    textAndMediaContentReference,
    trustpilotRef,
    optionalExperienceCardsCollection,
    optionalTextAndMediaContentReference,
    linkboxSlider,
    backToOverallExpTitle,
    backToOverallExpTextAndMediaContentRef,
    optionalNewsletterSubscribeBlock,
    faqSection,
    sys,
  } = content.page

  return (
    <PageLayout>
      {hero?.contentRef && (
        <>
          <Hero {...hero.contentRef} miniBookReference={miniBookReference} />

          {covidBannerLayout?.localisedContentReference?.header &&
            typeof sessionStorage !== 'undefined' &&
            sessionStorage.covidPaxBannerNotVisible !== 'true' && (
              <PaxCovidBanner
                {...covidBannerLayout?.localisedContentReference}
              />
            )}
        </>
      )}
      <TextAndMediaLayout
        mediaWidth={{ md: 8 }}
        reverse
        makeTitleAnH1
        title={textAndMediaContentReference.title}
        text={textAndMediaContentReference.text}
        images={textAndMediaContentReference.imagesCollection.items}
        links={textAndMediaContentReference.linksCollection.items}
        {...textAndMediaContentReference}
      />
      {trustpilotRef && (
        <Trustpilot
          {...trustpilotRef}
          template="cabinPage"
          id={sys.id}
          item="trustpilotRef"
        />
      )}
      {optionalExperienceCardsCollection &&
        optionalExperienceCardsCollection.items.map((item, i) => {
          return <ExperienceCard {...item} index={i} key={i} />
        })}
      {optionalTextAndMediaContentReference && (
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          reverse
          title={optionalTextAndMediaContentReference.title}
          text={optionalTextAndMediaContentReference.text}
          images={optionalTextAndMediaContentReference.imagesCollection.items}
          links={optionalTextAndMediaContentReference.linksCollection.items}
          {...optionalTextAndMediaContentReference}
        />
      )}
      <LinkboxCarousel {...linkboxSlider} />
      {backToOverallExpTextAndMediaContentRef && (
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          title={
            backToOverallExpTitle ||
            backToOverallExpTextAndMediaContentRef.title
          }
          text={backToOverallExpTextAndMediaContentRef.text}
          images={backToOverallExpTextAndMediaContentRef.imagesCollection.items}
          links={backToOverallExpTextAndMediaContentRef.linksCollection.items}
          {...backToOverallExpTextAndMediaContentRef}
        />
      )}
      {optionalNewsletterSubscribeBlock && (
        <NewsletterSubscribeBlock {...optionalNewsletterSubscribeBlock} />
      )}
      {faqSection?.itemsCollection?.items &&
        faqSection?.itemsCollection?.items.length !== 0 && (
          <Faq
            {...faqSection.itemsCollection}
            heading={faqSection?.heading}
            urlSlug={faqSection?.urlSlug?.slug}
            defaultExpanded={faqSection?.defaultExpanded ?? false}
          />
        )}
      <PriceDisclaimer />
    </PageLayout>
  )
}

export const query = graphql`
  query CabinPage($id: String!, $locale: String!, $preview: Boolean = false) {
    content: contentful {
      page: cabinPage(id: $id, locale: $locale, preview: $preview) {
        sys {
          id
        }
        hero: heroReference {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        covidBannerLayout {
          localisedContentReference {
            ...CovidBanner
          }
        }
        miniBookReference {
          ...Minibook
        }
        textAndMediaTitle
        textAndMediaContentReference {
          ...TextAndMediaContent
        }
        trustpilotRef {
          ...Trustpilot
        }
        optionalExperienceCardsCollection(limit: 6) {
          items {
            ...ExperienceCardContent
          }
        }
        optionalTextAndMediaContentReference {
          ...TextAndMediaContent
        }
        linkboxSlider {
          ...LinkboxCarousel
        }
        backToOverallExpTitle
        backToOverallExpTextAndMediaContentRef {
          ...TextAndMediaContent
        }
        optionalNewsletterSubscribeBlock {
          ...NewsletterSubscribeBlock
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default CabinPage
