import { graphql } from 'gatsby'
import { useQuery, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Text } from '@dfds-ui/typography'

import { PageLayout } from '../components/Layout'
import Hero from '../components/Hero/Hero'
import { useLocaleContext } from '../components/LocaleContext'
import { TextAndMediaLayout } from '../components/TextAndMedia'
import { PageContentTextSection } from '../components/PageContentTextSection'
import { IconBlockList } from '../components/IconBlockList'
import { gridMap, isContentfulType } from '../utils'
import { Faq } from '../components/FAQ'

const FEEDQUERY = gql`
  query FeedQuery($id: String!, $locale: String!, $preview: Boolean = false) {
    liveQuarterlyReport(id: $id, locale: $locale, preview: $preview) {
      introText {
        text
        title
      }
      iconRow {
        iconBlocksCollection {
          items {
            title
            icon {
              url
              title
            }
          }
        }
      }
      contentRowsCollection {
        items {
          ... on TextAndMediaLayout {
            __typename
            backgroundColor
            blockWidth
            sys {
              id
            }
            contentRef {
              ... on TextAndMediaContent {
                title
                text
                linksCollection(limit: 5) {
                  items {
                    ... on Link {
                      linkText
                      callToAction
                      description
                      destination {
                        ... on UrlSlug {
                          slug
                        }
                      }
                    }
                    ... on Url {
                      url
                      linkText
                      description
                    }
                    ... on AssetLink {
                      linkText
                      presentation
                      asset {
                        title
                        url
                      }
                    }
                  }
                }
                mediaType
                videoId
                videoOverlayImage {
                  url
                }
                imagesCollection(limit: 5) {
                  items {
                    title
                    url
                  }
                }
              }
            }
            imageBehaviour
            mediaPosition
            mediaWidth
          }
          ... on PageContentTextSection {
            __typename
            sys {
              id
            }
            backgroundColor
            showTitle
            blockWidth
            contentRef {
              title
              text
              linksCollection(limit: 4) {
                items {
                  __typename
                }
              }
            }
          }
        }
      }
    }
  }
`

const LiveQuarterlyReport = ({ data: { content } }) => {
  const { t } = useTranslation()
  const { locale } = useLocaleContext()
  const {
    hero,
    sys: { id },
    faqSection,
  } = content.page

  const { error, data } = useQuery(FEEDQUERY, {
    fetchPolicy: 'network-only',
    variables: {
      id: id || null,
      locale: locale || 'en',
    },
  })

  return (
    <PageLayout>
      {hero?.contentRef && <Hero {...hero.contentRef} />}
      {error && <Text styledAs={'sectionHeadline'}>{t('ERROR_MESSAGE')}</Text>}
      {data && data.liveQuarterlyReport.introText && (
        <TextAndMediaLayout
          reverse
          mediaWidth={{ md: 5 }}
          {...data.liveQuarterlyReport.introText}
        />
      )}
      {data && data.liveQuarterlyReport.iconRow?.iconBlocksCollection.items && (
        <IconBlockList
          iconBlocksCollection={
            data.liveQuarterlyReport.iconRow.iconBlocksCollection
          }
        />
      )}
      {data?.liveQuarterlyReport?.contentRowsCollection?.items &&
        data.liveQuarterlyReport.contentRowsCollection.items.map((item) => {
          if (
            isContentfulType(item.__typename, 'contentful_TextAndMediaLayout')
          ) {
            return (
              <TextAndMediaLayout
                key={item.sys.id}
                reverse
                mediaWidth={gridMap[item.mediaWidth]}
                {...item}
              />
            )
          }
          if (
            isContentfulType(
              item.__typename,
              'contentful_PageContentTextSection',
            )
          ) {
            return <PageContentTextSection key={item.sys.id} {...item} />
          }
        })}
      {faqSection?.itemsCollection?.items &&
        faqSection?.itemsCollection?.items.length !== 0 && (
          <Faq
            {...faqSection.itemsCollection}
            heading={faqSection?.heading}
            urlSlug={faqSection?.urlSlug?.slug}
            defaultExpanded={faqSection?.defaultExpanded ?? false}
          />
        )}
    </PageLayout>
  )
}

export const query = graphql`
  query LiveQuarterlyReportPage(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: liveQuarterlyReport(id: $id, locale: $locale, preview: $preview) {
        sys {
          id
        }
        hero: heroReference {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default LiveQuarterlyReport
