import { graphql } from 'gatsby'
import { PageLayout } from '../components/Layout'
import { isContentfulType } from '../utils'
import { LinkboxCarousel } from '../components/LinkboxCarousel'
import { TextAndMediaLayout } from '../components/TextAndMedia'
import { PaxProductCard } from '../components/PaxProductCard'
import { TripAtAGlance } from '../components/TripAtAGlance'
import { PageContentTextSection } from '../components/PageContentTextSection'
import { Trustpilot } from '../components/Trustpilot'
import { NewsletterSubscribeBlock } from '../components/Newsletter'
import { TourOperatorWidget } from '../components/Widgets'
import { PriceDisclaimer } from '../components/PriceDisclaimer'
import { Faq } from '../components/FAQ'
import { PaxCovidBanner } from '../components/PaxCovidBanner'
import { PaxTimeTable } from '../components/PaxTimeTable'
import Hero from '../components/Hero/Hero'

const MiniCruiseAndPackageProductPage = ({ data: { content } }) => {
  const {
    hero,
    covidBannerLayout,
    miniBookReference,
    productCardReference,
    tripAtAGlanceReference,
    textAndMedia1ContentReference,
    optionalTextAndMediaContentReference,
    tourOperatorWidget,
    termsAndConditionsReference,
    ourRestaurantsLinkboxSliderTitle,
    linkboxSlider1Reference,
    ourCabinsLinkboxSliderTitle,
    linkboxSlider2Reference,
    onboardExperienceLinkboxSliderTitle,
    linkboxSlider3Reference,
    textAndMedia2ContentReference,
    textAndMedia3ContentReference,
    trustpilotRef,
    additionalTrustpilotRef,
    awardTextAndImageReference,
    optionalNewsletterSubscribeBlock,
    faqSection,
    sys,
  } = content.page

  return (
    <>
      <PageLayout>
        {hero?.contentRef && (
          <>
            <Hero {...hero.contentRef} miniBookReference={miniBookReference} />

            {covidBannerLayout?.localisedContentReference?.header &&
              typeof sessionStorage !== 'undefined' &&
              sessionStorage.covidPaxBannerNotVisible !== 'true' && (
                <PaxCovidBanner
                  {...covidBannerLayout?.localisedContentReference}
                />
              )}
          </>
        )}
        {tourOperatorWidget && <TourOperatorWidget {...tourOperatorWidget} />}
      </PageLayout>
      <PageLayout
        sidebar={
          (isContentfulType(
            tripAtAGlanceReference.__typename,
            'contentful_PaxTimeTable',
          ) && <PaxTimeTable {...tripAtAGlanceReference} />) ||
          (isContentfulType(
            tripAtAGlanceReference.__typename,
            'contentful_TripAtAGlance',
          ) && <TripAtAGlance {...tripAtAGlanceReference} />) ||
          (isContentfulType(
            tripAtAGlanceReference.__typename,
            'contentful_TextAndMediaContent',
          ) && (
            <TextAndMediaLayout
              title={tripAtAGlanceReference.title}
              text={tripAtAGlanceReference.text}
              images={tripAtAGlanceReference.imagesCollection.items}
              links={tripAtAGlanceReference.linksCollection.items}
              lightboxes={tripAtAGlanceReference.lightboxesCollection.items}
              {...tripAtAGlanceReference}
            />
          ))
        }
      >
        <PaxProductCard {...productCardReference} />
      </PageLayout>
      <PageLayout>
        {trustpilotRef && (
          <Trustpilot
            {...trustpilotRef}
            template="miniCruiseAndPackageProductPage"
            id={sys.id}
            item="trustpilotRef"
          />
        )}
      </PageLayout>
      <PageLayout>
        <TextAndMediaLayout
          reverse
          mediaWidth={{ md: 8 }}
          {...textAndMedia1ContentReference}
        />
        {optionalTextAndMediaContentReference && (
          <TextAndMediaLayout
            mediaWidth={{ md: 8 }}
            reverse
            title={optionalTextAndMediaContentReference.title}
            text={optionalTextAndMediaContentReference.text}
            images={optionalTextAndMediaContentReference.imagesCollection.items}
            links={optionalTextAndMediaContentReference.linksCollection.items}
            {...optionalTextAndMediaContentReference}
          />
        )}
        {termsAndConditionsReference && (
          <PageContentTextSection {...termsAndConditionsReference} />
        )}
        {additionalTrustpilotRef && (
          <Trustpilot
            {...additionalTrustpilotRef}
            template="miniCruiseAndPackageProductPage"
            id={sys.id}
            item="additionalTrustpilotRef"
          />
        )}
        {linkboxSlider1Reference && (
          <LinkboxCarousel
            {...linkboxSlider1Reference}
            title={
              ourRestaurantsLinkboxSliderTitle || linkboxSlider1Reference.title
            }
          />
        )}
        {linkboxSlider2Reference && (
          <LinkboxCarousel
            {...linkboxSlider2Reference}
            title={ourCabinsLinkboxSliderTitle || linkboxSlider2Reference.title}
          />
        )}
        {linkboxSlider3Reference && (
          <LinkboxCarousel
            {...linkboxSlider3Reference}
            title={
              onboardExperienceLinkboxSliderTitle ||
              linkboxSlider3Reference.title
            }
          />
        )}
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          reverse
          {...textAndMedia2ContentReference}
        />
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          {...textAndMedia3ContentReference}
        />
        <TextAndMediaLayout
          reverse
          mediaWidth={{ md: 8 }}
          {...awardTextAndImageReference}
        />
        {optionalNewsletterSubscribeBlock && (
          <NewsletterSubscribeBlock {...optionalNewsletterSubscribeBlock} />
        )}
        {faqSection?.itemsCollection?.items &&
          faqSection?.itemsCollection?.items.length !== 0 && (
            <Faq
              {...faqSection.itemsCollection}
              heading={faqSection?.heading}
              urlSlug={faqSection?.urlSlug?.slug}
              defaultExpanded={faqSection?.defaultExpanded ?? false}
            />
          )}
        <PriceDisclaimer />
      </PageLayout>
    </>
  )
}

export const query = graphql`
  query MiniCruiseAndPackageProductPage(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: miniCruiseAndPackageProductPage(
        id: $id
        locale: $locale
        preview: $preview
      ) {
        sys {
          id
        }
        hero: heroReference {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        covidBannerLayout {
          localisedContentReference {
            ...CovidBanner
          }
        }
        miniBookReference {
          ...Minibook
          ...MinibookProductList
        }
        productCardReference {
          ...PaxProductCard
        }
        tripAtAGlanceReference {
          __typename
          ...TripAtAGlance
          ...TextAndMediaContent
          ...PaxTimeTable
        }
        tourOperatorWidget {
          ...TourOperatorWidget
        }
        textAndMedia1ContentReference {
          ...TextAndMediaContent
        }
        optionalTextAndMediaContentReference {
          ...TextAndMediaContent
        }
        termsAndConditionsReference {
          ...PageContentTextSection
        }
        ourRestaurantsLinkboxSliderTitle
        linkboxSlider1Reference {
          ...LinkboxCarousel
        }
        ourCabinsLinkboxSliderTitle
        linkboxSlider2Reference {
          ...LinkboxCarousel
        }
        onboardExperienceLinkboxSliderTitle
        linkboxSlider3Reference {
          ...LinkboxCarousel
        }
        textAndMedia2ContentReference {
          ...TextAndMediaContent
        }
        textAndMedia3ContentReference {
          ...TextAndMediaContent
        }
        trustpilotRef {
          ...Trustpilot
        }
        additionalTrustpilotRef {
          ...Trustpilot
        }
        awardTextAndImageReference {
          ...TextAndMediaContent
        }
        optionalNewsletterSubscribeBlock {
          ...NewsletterSubscribeBlock
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default MiniCruiseAndPackageProductPage
