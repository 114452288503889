import { graphql } from 'gatsby'
import { PageLayout } from '../components/Layout'
import { Trustpilot } from '../components/Trustpilot'
import { LinkboxCarousel } from '../components/LinkboxCarousel'
import { TextAndMediaLayout } from '../components/TextAndMedia'
import { PriceDisclaimer } from '../components/PriceDisclaimer'
import { NewsletterSubscribeBlock } from '../components/Newsletter'
import { Faq } from '../components/FAQ'
import { PaxCovidBanner } from '../components/PaxCovidBanner'
import Hero from '../components/Hero/Hero'

const CountryPage2Gmr = ({ data: { content } }) => {
  const {
    hero,
    covidBannerLayout,
    minibookReference,
    textAndImageReference,
    ourRoutesLinkboxSliderTitle,
    ourRoutesLinkboxSlider,
    textAndGallery1Reference,
    textAndGallery2Reference,
    trustpilotRef,
    additionalTrustpilotRef,
    ourTravelGuideLinkboxSliderTitle,
    ourTravelGuideLinkboxSlider,
    optionalNewsletterSubscribeBlock,
    faqSection,
    sys,
  } = content.page

  return (
    <PageLayout>
      {hero?.contentRef && (
        <>
          <Hero {...hero.contentRef} miniBookReference={minibookReference} />

          {covidBannerLayout?.localisedContentReference?.header &&
            typeof sessionStorage !== 'undefined' &&
            sessionStorage.covidPaxBannerNotVisible !== 'true' && (
              <PaxCovidBanner
                {...covidBannerLayout?.localisedContentReference}
              />
            )}
        </>
      )}
      <TextAndMediaLayout
        mediaWidth={{ md: 8 }}
        reverse
        makeTitleAnH1
        title={textAndImageReference.title}
        text={textAndImageReference.text}
        images={textAndImageReference.imagesCollection.items}
        links={textAndImageReference.linksCollection.items}
        {...textAndImageReference}
      />
      {trustpilotRef && (
        <Trustpilot
          {...trustpilotRef}
          template="countryPage2GMR"
          id={sys.id}
          item="trustpilotRef"
        />
      )}
      <LinkboxCarousel
        {...ourRoutesLinkboxSlider}
        title={ourRoutesLinkboxSliderTitle || ourRoutesLinkboxSlider.title}
      />
      {additionalTrustpilotRef && (
        <Trustpilot
          {...additionalTrustpilotRef}
          template="countryPage2GMR"
          id={sys.id}
          item="additionalTrustpilotRef"
        />
      )}
      <TextAndMediaLayout
        mediaWidth={{ md: 8 }}
        reverse
        title={textAndGallery1Reference.title}
        text={textAndGallery1Reference.text}
        images={textAndGallery1Reference.imagesCollection.items}
        links={textAndGallery1Reference.linksCollection.items}
        {...textAndGallery1Reference}
      />
      {textAndGallery2Reference && (
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          title={textAndGallery2Reference.title}
          text={textAndGallery2Reference.text}
          images={textAndGallery2Reference.imagesCollection.items}
          links={textAndGallery2Reference.linksCollection.items}
          {...textAndGallery2Reference}
        />
      )}
      <LinkboxCarousel
        {...ourTravelGuideLinkboxSlider}
        title={
          ourTravelGuideLinkboxSliderTitle || ourTravelGuideLinkboxSlider.title
        }
      />
      {optionalNewsletterSubscribeBlock && (
        <NewsletterSubscribeBlock {...optionalNewsletterSubscribeBlock} />
      )}
      {faqSection?.itemsCollection?.items &&
        faqSection?.itemsCollection?.items.length !== 0 && (
          <Faq
            {...faqSection.itemsCollection}
            heading={faqSection?.heading}
            urlSlug={faqSection?.urlSlug?.slug}
            defaultExpanded={faqSection?.defaultExpanded ?? false}
          />
        )}
      <PriceDisclaimer />
    </PageLayout>
  )
}

export const query = graphql`
  query CountryPage2Gmr(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: countryPage2Gmr(id: $id, locale: $locale, preview: $preview) {
        sys {
          id
        }
        hero: heroReference {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        covidBannerLayout {
          localisedContentReference {
            ...CovidBanner
          }
        }
        minibookReference {
          ...Minibook
        }
        textAndImageReference {
          ...TextAndMediaContent
        }
        ourRoutesLinkboxSliderTitle
        ourRoutesLinkboxSlider {
          ...LinkboxCarousel
        }
        textAndGallery1Reference {
          ...TextAndMediaContent
        }
        textAndGallery2Reference {
          ...TextAndMediaContent
        }
        ourTravelGuideLinkboxSliderTitle
        ourTravelGuideLinkboxSlider {
          ...LinkboxCarousel
        }
        trustpilotRef {
          ...Trustpilot
        }
        additionalTrustpilotRef {
          ...Trustpilot
        }
        optionalNewsletterSubscribeBlock {
          ...NewsletterSubscribeBlock
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default CountryPage2Gmr
