const { mergeDeepWith, concat, mergeWith, isNil } = require('ramda')

function hasOnlyTypename(item) {
  return (
    !isNil(item) &&
    typeof item === 'object' &&
    Object.keys(item).length === 1 &&
    typeof item.__typename === 'string'
  )
}

function removeEmptyObjectsFromList(items) {
  return items.filter((item) => !hasOnlyTypename(item))
}

function hasSysId(item) {
  return item.sys && item.sys.id
}

function deepMerge(left, right) {
  if (Array.isArray(left) && Array.isArray(right)) {
    left = removeEmptyObjectsFromList(left)
    right = removeEmptyObjectsFromList(right)
    // merge by sys.id but only if it exists in every entry
    if (left.every(hasSysId) && right.every(hasSysId)) {
      const newList = []

      left.slice().forEach((lItem) => {
        const duplicate = right
          .slice()
          .find((rItem) => rItem.sys.id === lItem.sys.id)

        if (duplicate) {
          newList.push(mergeWith(deepMerge, lItem, duplicate))
        } else {
          newList.push(lItem)
        }
      })

      right.slice().forEach((rItem) => {
        const exists = left
          .slice()
          .some((lItem) => lItem.sys.id === rItem.sys.id)

        if (!exists) {
          newList.push(rItem)
        }
      })

      return newList
    }

    return concat(left, right)
  } else if (
    typeof left === 'object' &&
    typeof right === 'object' &&
    !isNil(left) &&
    !isNil(right)
  ) {
    return mergeWith(deepMerge, left, right)
  } else {
    return left || right
  }
}

function mergeQueries(left, right) {
  return mergeDeepWith(deepMerge, left, right)
}

exports.mergeQueries = mergeQueries
